.App {
    /*text-align: center;*/
    background-color: white;
    margin: 0;
    padding: 0;
}

.navBar {
    z-index: 1;
    width: 110%;
    top: 0;
    text-align: center;
    max-height: 64px;
    box-shadow: 5px 1px 30px 0 #888888;
    background: linear-gradient(to right, #258947, #2b8712);
}

#script {
    height: 600px;
    width: 600px;
}

#sidenav-overlay {
    z-index: 1;
}

.itemBackground {
    background-color: #258947;
}

.navText {
    color: white;
    font-weight: bolder;
    font-size: 14px;
}

.navDropDownText {
    color: black;
}

.side-nav a {
    padding: 0;
}

.side-nav {
    background: #258947;
}

.dropdown-content {
    background-color: white;
    border-radius: 2px;
    margin-bottom: 1px;
}

.pullDownCont {
    margin: 0;
    padding: 0;
    text-align: center;
}

.getLicensedLogo {
    height: 80%;
    width: 80%;
    max-height: 1000px;
    max-width: 1200px;
    margin-top: 40px;
    padding: 0;
}

.title {
    text-align: center;
    padding: 0px 15px 15px 15px;
    font-size: 40px;
    font-weight: 800;
    line-height: 120%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.numOneTitle {
    text-align: center;
    padding: 0px 15px 15px 15px;
    font-size: 35px;
    font-weight: 800;
    line-height: 120%;
    /*background-color: red;*/
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.outerCont {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    height: auto;
}

.main {
    text-align: center;
    /* margin-top: 100px; */
    width: 100%;
    min-height: 65vh;
    margin-left: auto;
    margin-right: auto;
}

.pageTextMarg {
    width: 88%;
    margin-right: auto;
    margin-left: auto;
}

.boldText {
    font-family: "Circular Pro", "Noto Sans Japanese", sans-serif;
    font-size: 26px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    width: 85%;
}

.howToBlock {
    height: 30%;
    margin-top: 5%;
    width: 88%;
    margin-right: auto;
    margin-left: auto;
}

#video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
#video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.numOneInIndBlock {
    height: 50%;
    margin-top: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    color: black;
    /*width: 85%;*/
    margin-right: auto;
    margin-left: auto;
    /*background: linear-gradient(to right, #259e14, #2bc417, #33f719);;*/
}

.homeThirdBlock {
    height: 60%;
    margin-top: 3%;
    margin-bottom: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
    width: 88%;
    margin-right: auto;
    margin-left: auto;
}

.wrapper {
    /* The height needs to be set to a fixed value for the effect to work.
     * 100vh is the full height of the viewport. */
    height: 100vh;
    /* The scaling of the images would add a horizontal scrollbar, so disable x overflow. */
    overflow-x: hidden;
    /* Enable scrolling on the page. */
    overflow-y: auto;
    /* Set the perspective to 2px. This is essentailly the simulated distance from the viewport to transformed objects.*/
    perspective: 2px;
}

.companySetupLinks {
    color: #258947;
    font-weight: bold;
}

.name {
    font-weight: bold;
    font-size: 30px;
    margin: 0;
}

.jobTitle {
    font-weight: bold;
    font-size: 22px;
}

.profilePic {
    height: 80%;
    width: 69%;
    min-height: 280px;
    min-width: 240px;
    max-height: 476px;
    max-width: 350px;
    border-radius: 7px;
    margin-top: 5%;
    margin-bottom: 1%;
    box-shadow: 5px 13px 40px 10px #888888;
}

.bruceProfilePic {
    height: 80%;
    width: 69%;
    min-height: 280px;
    min-width: 260px;
    max-height: 600px;
    max-width: 490px;
    border-radius: 7px;
    margin-top: 5%;
    margin-bottom: 1%;
    box-shadow: 5px 13px 40px 10px #888888;
}

.profileImgCont {
    margin-top: 1%;
    margin-bottom: 1%;
}

.aboutTextBlock {
    margin-right: auto;
    margin-left: auto;
    margin-top: 4%;
    margin-bottom: 4%;
    width: 88%;
}

.aboutImageBlock {
    height: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.aboutGeneralText {
    font-family: "Circular Pro", "Noto Sans Japanese", sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-top: 5%;
    margin-bottom: 5%;
}

.flow-text {
    font-weight: 400;
    margin-left: 1%;
    margin-right: 1%;
}

.licenseStep {
    font-family: "Circular Pro", "Noto Sans Japanese", sans-serif;
    font-size: 26px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
}

.weWillNotRun {
    text-align: center;
    padding: 0px 15px 15px 15px;
    font-weight: 800;
    line-height: 120%;
    /*background-color: red;*/
    margin-top: 5%;
    margin-bottom: 3%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.collapseTitle {
    font-weight: bold;
}

.collapseCont {
    margin-top: 5%;
    margin-bottom: 10%;
    font-weight: 400;
    font-size: large;
}

.licenseClassificationsCont {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.licenseClassifications {
    text-align: center;
}

.outerContStore {
    margin-top: 5vh;
    width: 100%;
    text-align: center;
    height: 100%;
}

.storeItemCont {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-bottom: 2%;
    color: white;
    background: linear-gradient(to right, #252324, #252324, #252324);
    width: 70%;
    height: 90%;
    font-size: 35px;
    border-radius: 22px;
    border: 2px solid;
    border-color: #19d100;
    font-weight: bolder;
    font-family: Georgia;
    box-shadow: 10px 10px 20px 5px #888888;
}

.storeItem {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-bottom: 3%;
}

.smallBoldText {
    font-weight: bolder;
}

.makePayCont {
    margin-top: 200px;
}

.examDoc {
    margin-top: 30px;
}

.priceBox {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
    color: white;
    background: linear-gradient(to right, #252324, #252324, #252324);
    width: 55%;
    height: 80%;
    font-size: 19px;
    border-radius: 22px;
    border: 2px solid;
    border-color: #19d100;
    font-weight: bolder;
    font-family: Georgia;
    box-shadow: 10px 10px 20px 5px #888888;
}

.dollarAmount {
    font-size: 60px;
    margin: auto;
}

.tables {
    border: 5px solid black;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.autoCont {
    margin-top: 9%;
    margin-bottom: 8%;
}

.indLinksCont {
    margin-top: 7vh;
    padding-top: 3vh;
    min-height: 500px;
}

.indLinksInnerCont {
    margin-top: 2vh;
}

.socialCont {
    padding-bottom: 7vh;
    padding-top: 5vh;
}

.footerMain {
    /*position: fixed;*/
    bottom: -8px;
    padding: 0;
    margin: 0;
    min-height: 10px;
    width: 100%;
    background-color: #258947;
    /* background: linear-gradient(to right, #17a300, #2ec70d); */
    background: #258947;
}

.footerTextCont {
    width: 100%;
    height: 100%;
    font-size: 13px;
    text-align: center;
}

.footerText {
    color: white;
    margin: 0;
    width: 100%;
}

.fa {
    margin-left: 20px;
    margin-right: 20px;
    color: black;
}

.faqInnerTitle {
    font-weight: bolder;
}
